/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/*Bootstrap Icons*/

@import '~bootstrap-icons/font/bootstrap-icons.css';

@import '~@fortawesome/fontawesome-free/css/all.css';

.p-inputtext {
  &.ng-touched.ng-invalid,
  &.ng-dirty.ng-invalid {
    border: 1px solid #fe6262;
  }
}

p-dropdown.ng-touched.ng-invalid,
p-dropdown.ng-dirty.ng-invalid {
  .p-dropdown {
    border: 1px solid #fe6262;
  }
}

p-calendar {
  &.ng-touched.ng-invalid,
  &.ng-dirty.ng-invalid {
    & > .p-calendar > .p-inputtext {
      border: 1px solid #fe6262;
    }
  }
}

p-multiselect.ng-touched.ng-invalid,
p-multiselect.ng-dirty.ng-invalid {
  .p-multiselect {
    border: 1px solid #fe6262;
  }
}

.container-user-menu .user-menu-header .p-menu-overlay {
  margin-right: 10px !important;
}
